import request from '@/http/axios'
import Api from '@/http/api'

/**
 * 角色添加
 * @param {object} data
 * @returns
 */
export const addRole = (data) =>
  request({
    url: Api.role.add,
    method: 'post',
    data
  })

/**
 * 获取角色列表
 * @param {object} data
 * @returns
 */
export const getRoleList = (data) =>
  request({
    url: Api.role.list,
    method: 'post',
    data: data
  })

/**
 * 删除角色
 * @param {object} data
 * @returns
 */
export const deleteRole = (data) =>
  request({
    url: Api.role.delete,
    method: 'post',
    data
  })

/**
 * 获取角色信息
 * @param {object} data
 * @returns
 */
export const getRoleView = (data) =>
  request({
    url: Api.role.view,
    method: 'post',
    data: data
  })

/**
 * 角色启用
 * @param {object} data
 * @returns
 */
export const setRoleEnable = (data) =>
  request({
    url: Api.role.enable,
    method: 'post',
    data: data
  })

/**
 * 角色禁用
 * @param {object} data
 * @returns
 */
export const setRoleDisable = (data) =>
  request({
    url: Api.role.disable,
    method: 'post',
    data: data
  })
/**
 * 角色编辑
 * @param {object} data
 * @returns
 */
export const editRole = (data) =>
  request({
    url: Api.role.edit,
    method: 'post',
    data
  })
