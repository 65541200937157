<template>
  <div class="role-list">
    <div class="outer">
      <search-panel
        show-btn
        flex
        @getList="getList"
      >
        <div>
          <p>角色名称</p>
          <el-input
            v-model="roleName"
            clearable
            placeholder="请输入角色名称"
            size="mini"
          />
        </div>
        <div>
          <p>创建时间</p>
          <el-date-picker
            v-model="tagtime"
            size="mini"
            type="datetimerange"
            align="right"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          />
        </div>
        <div>
          <p>角色状态</p>
          <el-select
            v-model="roleState"
            size="mini"
            clearable
            @change="getList"
          >
            <el-option
              v-for="(item,index) in roleStateArr"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <el-button
          slot="button-right"
          type="primary"
          size="mini"
          @click="operation('add')"
        >
          新 增
        </el-button>
        <el-button
          slot="button-right"
          style="margin: 0 0 0 20px"
          type="primary"
          size="mini"
          @click="operation('multipleDel')"
        >
          批量删除
        </el-button>
        <el-button
          v-show="multipleSelection.length > 0"
          slot="button-right"
          style="margin: 0 0 0 20px"
          size="mini"
          type="danger"
          @click="multipleDelSubmit()"
        >
          确认删除
        </el-button>
      </search-panel>

      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="tableData"
        border
        :header-cell-style="{'background': '#D9DDE1', 'color': '#0D0202', 'font-weight': 400}"
        element-loading-text="拼命加载中"
        row-key="id"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          v-if="multipleSelectShow"
          type="selection"
          :reserve-selection="true"
          width="55"
        />
        <el-table-column
          prop="id"
          label="ID"
          width="90"
        />
        <el-table-column
          prop="roleName"
          label="角色名称"
          width="120"
        />
        <el-table-column
          prop="roleDesc"
          label="角色说明"
        />
        <el-table-column
          prop="createTime"
          label="创建时间"
          width="120"
        />
        <el-table-column label="状态">
          <template slot-scope="scope">
            {{ ['启用','禁用'][Number(scope.row.roleState)] }}
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="操作"
          width="350"
        >
          <template slot-scope="scope">
            <div>
              <el-button
                v-if="scope.row.roleState"
                type="success"
                size="mini"
                @click="disableOperation('enable', scope.row)"
              >
                启用
              </el-button>
              <el-button
                v-if="!scope.row.roleState"
                type="warning"
                size="mini"
                @click="disableOperation('disable', scope.row)"
              >
                禁用
              </el-button>
              <el-button
                type="primary"
                size="mini"
                @click="operation('edit', scope.row)"
              >
                编辑
              </el-button>
              <el-button
                type="danger"
                size="mini"
                @click="deleteOperation('delete',[scope.row.roleId])"
              >
                删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData && tableData.length > 0"
        :current-page="pagination.currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagination.nowPageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.count"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { setRoleDisable, setRoleEnable, deleteRole } from '@/service/roleService';

export default {
  name: 'role-list',
  data() {
    return {
      roleName: null,
      tableData: [],
      legal_person: '',
      loading: false,
      createStartTime: null,
      createEndTime: null,
      tagtime: [],
      bankNumber: '', // 账号
      roleState: null, // 邀请状态
      roleStateArr: [
        {
          label: '全部',
          value: null,
        },
        {
          label: '启用',
          value: 0,
        },
        {
          label: '禁用',
          value: 1,
        },
      ], // 角色状态
      multipleSelection: [], // 多选
      multipleSelectShow: false, // 多选控制器
      // 分页
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0,
      },
    };
  },
  watch: {
    tagtime(item) {
      if (item) {
        this.createStartTime = item[0];
        this.createEndTime = item[1];
      } else {
        this.createStartTime = null;
        this.createEndTime = null;
      }
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 分页控制
    handleSizeChange(val) {
      this.pagination.nowPageSize = val;
      this.pagination.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.$axios({
        method: 'post',
        url: this.$api.role.list,
        data: {
          currentPage: this.pagination.currentPage,
          pageSize: this.pagination.nowPageSize,
          roleName: this.roleName,
          roleState: this.roleState,
          startTime: this.createStartTime ? this.setTime(this.createStartTime) : null,
          endTime: this.createEndTime ? this.setTime(this.createEndTime) : null,
        },
      }).then((res) => {
        if (res.code === 0) {
          this.tableData = res.data.records;
          this.pagination.count = Number(res.data.total);
        } else {
          this.$message(res.error_msg);
        }
        this.loading = false;
      });
    },
    // 删除
    deleteOperation(type, row) {
      console.log(type, row);
      this.$confirm('确定删除此角色吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        deleteRole({ roleIdList: row }).then((res) => {
          if (res.code === 0) {
            this.$message.success('操作成功');
            this.getList();
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除',
        });
      });
    },
    // 禁用和启用
    disableOperation(type, row) {
      console.log('row', row);
      this.$confirm('确定修改此角色状态吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        if (type === 'disable') {
          setRoleDisable({ roleId: row.roleId }).then((res) => {
            if (res.code === 0) {
              this.$message.success('操作成功');
              this.getList();
            }
          });
        } else {
          setRoleEnable({ roleId: row.roleId }).then((res) => {
            if (res.code === 0) {
              this.$message.success('操作成功');
              this.getList();
            }
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作',
        });
      });
    },
    operation(type, row) {
      if (type === 'add') {
        this.$router.push('/user/role/detail?type=add');
      } else if (type === 'edit') {
        this.$router.push({ path: '/user/role/detail', query: { type, id: row.roleId } });
      } else if (type === 'multipleDel') {
        this.multipleSelectShow = true;
      }
    },

    handleSelectionChange(val) {
      let tempArr = [];
      val.forEach((element) => {
        tempArr.push(element.roleId);
      });
      this.multipleSelection = tempArr;
      console.log('多选', this.multipleSelection);
    },
    // 提交删除
    multipleDelSubmit() {
      this.deleteOperation('delete', this.multipleSelection);
    },
    setTime(time) {
      return dayjs(Number(time)).format('YYYY-MM-DD HH:mm:ss');
    },
  },
};
</script>

<style scoped lang="scss">
</style>
